import { axiosInstance } from "../../AppUtils/Utils/axios";

//get next
export const getNext = (next: string) => axiosInstance.get(next);
//get previous
export const getPrevious = (next: string) => axiosInstance.get(next);

const OrganizationRuleModURL = "/api/v1/global-api/organization-rule";
const OrganizationModURL ="/api/v1/global-api/organization-setup";


export const getGlobalOrganizationSetup = () => {
    return axiosInstance.get(`${OrganizationModURL}`);
  };
  export const getGlobalOrganizationRule = () => {
    return axiosInstance.get(`${OrganizationRuleModURL}`);
  };

  