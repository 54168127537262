import "./App.css";
import Layout from "./Component/Layout";
import PrivateRoutes from "./Routes/PrivateRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  alertErrorAction,
  closeModal,
} from "./Pages/CommonAppRedux/CommonAppSlice";
import { useAppSelector } from "./AppUtils/Utils/appHooks";
import { commonAppSelector } from "./Pages/CommonAppRedux/selector";
import CustomAlert from "./Component/CustomAlert/CustomAlert";
import { getBranchRequest, loginFail } from "./Pages/Auth/Redux/authSlice";
import { loginSelector } from "./Pages/Auth/Redux/selector";
import getCookie from "./AppUtils/Utils/Cookies/getCookie";
import Favicon from "./Component/Favicon";
function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { open, message } = useAppSelector(commonAppSelector);
  const { isAuthenticated,isEmailVerified } = useAppSelector(loginSelector);
  const isAuthenticatedCookie = getCookie("isAuthenticated");
  const { branches } = useAppSelector(loginSelector);
  const localEnvURL = JSON.stringify(process.env.REACT_APP_BASE_URL);
  const browserURLs = localStorage.getItem("envURL");

  useEffect(() => {
    //set modal false whenever route changed
    if (localEnvURL !== browserURLs) {
      localStorage.removeItem("envURL");
      dispatch(alertErrorAction("Subdomain Url Is Changed Login Again"));
      dispatch(loginFail());
      dispatch(getBranchRequest());
    }
    if (branches?.length === 0 || !isAuthenticatedCookie) {
      dispatch(getBranchRequest());
    }
    dispatch(closeModal());
  }, [location, dispatch]);

  return (
    <>
      {/* update favicon and title  */}
      <Favicon />
      {/* <CssBaseline /> */}
      {open && <CustomAlert openAlert={open} data={message} />}
      {isAuthenticatedCookie || isAuthenticated ? (
        <Layout>
          <PrivateRoutes />
        </Layout>
      ) : (
        <PublicRoutes />
       
      )}
    </>
  );
}

export default App;
