import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  searchProps,
  getApiProps,
} from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

export const depositModURL = `api/v1/account-app/deposit`;

//get deposit
export const getDeposit = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${depositModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//get Deposit by id
export const getDepositById = (id: number) => {
  return axiosInstance.get(`${depositModURL}/${id}`);
};
//search Deposit
export const searchDeposit = ({ rowsPerPage, search }: searchProps) => {
  return `${depositModURL}?offset=0&limit=${rowsPerPage}&search=${
    search ?? ""
  }`;
};
//update Deposit
export const updateDeposit = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${depositModURL}/${id}`, body);
//create Deposit
export const createDeposit = (body: string | FormData) =>
  axiosInstance.post(`${depositModURL}`, body);

export const getDepositCustomer = (rowsPerPage: number) =>
  axiosInstance.get(`${depositModURL}/customer?offset=0&limit=${rowsPerPage}`);

export const getDepositOrder = (type: string, rowsPerPage: number) => {
  return axiosInstance.get(
    `${depositModURL}/order?type=${type}&offset=0&limit=${rowsPerPage}`
  );
};
