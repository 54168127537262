/** @format */
import { combineEpics } from "redux-observable";
import { paymentModeEpics } from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeEpic";
import { userEpics } from "../Pages/CoreSetupPages/User/Redux/userEpic";
import { authEpics } from "../Pages/Auth/Redux/authEpic";
import { countryEpics } from "../Pages/CoreSetupPages/Country/Redux/countryEpic";
import { provinceEpics } from "../Pages/CoreSetupPages/Province/Redux/provinceEpic";
import { districtEpics } from "../Pages/CoreSetupPages/District/Redux/districtEpic";
import { palikaEpics } from "../Pages/CoreSetupPages/Palika/Redux/palikaEpic";
import { taxGroupEpics } from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupEpic";
import { organizationEpics } from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationEpic";
import { titleEpics } from "../Pages/CoreSetupPages/Title/Redux/titleEpic";
import { discountSchemaEpics } from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeEpic";
import { customerTypeEpics } from "../Pages/CoreSetupPages/CustomerType/Redux/customerTypeEpic";
import { storeEpics } from "../Pages/CoreSetupPages/Store/Redux/storeEpic";
import { userRoleEpics } from "../Pages/CoreSetupPages/UserRole/Redux/userRoleEpic";
import { additionalChargeEpic } from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeEpic";
import { bankEpic } from "../Pages/CoreSetupPages/Bank/Redux/BankEpic";
import { organizationRuleEpics } from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleEpic";
import { manufacturerEpics } from "../Pages/InventoryPages/Manufacturer/Redux/manufacturerEpic";
import { itemUnitEpics } from "../Pages/InventoryPages/ItemUnit/Redux/itemUnitEpic";
import { itemCategoryEpics } from "../Pages/InventoryPages/ItemCategory/Redux/itemCategoryEpic";
import { packingTypeEpics } from "../Pages/InventoryPages/PackingType/Redux/packingTypeEpic";
import { itemEpics } from "../Pages/InventoryPages/Item/Redux/itemEpic";
import { supplierEpics } from "../Pages/InventoryPages/Supplier/Redux/supplierEpic";
import { purchaseEpics } from "../Pages/InventoryPages/Purchase/Redux/purchaseEpic";
import { serviceEpics } from "../Pages/InventoryPages/Service/Redux/serviceEpic";
// lab epics
import { departmentEpics } from "../Pages/LabPages/Department/Redux/departmentEpic";
import { testCategoryEpics } from "../Pages/LabPages/TestCategory/Redux/testCategoryEpic";
import { footerNoteEpics } from "../Pages/LabPages/FooterNote/Redux/footerNoteEpic";
import { reportCommentEpics } from "../Pages/LabPages/ReportComment/Redux/reportCommentEpic";
import { sensitiveMedicineEpics } from "../Pages/LabPages/SensitiveMedicine/Redux/sensitiveMedicineEpic";
import { testMethodEpics } from "../Pages/LabPages/TestMethod/Redux/testMethodEpic";
import { unitEpic } from "../Pages/LabPages/Unit/Redux/UnitEpic";
import { testEpics } from "../Pages/LabPages/Test/Redux/testEpic";
import { packageTestEpics } from "../Pages/LabPages/PackageTest/Redux/packageTestEpic";
import { testSensitiveMedicineEpics } from "../Pages/LabPages/TestSensitiveMedicine/Redux/testSensitiveMedicineEpic";
import { testFindingEpics } from "../Pages/LabPages/TestFinding/Redux/testFindingEpic";
import { referenceRangeEpics } from "../Pages/LabPages/ReferenceRange/Redux/referenceRangeEpic";
import { reportFooterEpics } from "../Pages/LabPages/ReportFooter/Redux/reportFooterEpic";
import { resultEntryEpics } from "../Pages/LabPages/ResultEntry/Redux/ResultEntryEpic";
import { refundBillingEpics } from "../Pages/ClinicPages/RefundBilling/Redux/refundBillingEpic";
import { sampleTypeEpics } from "../Pages/LabPages/SampleType/Redux/sampleTypeEpic";
import { reagentEpics } from "../Pages/LabPages/Reagent/Redux/reagentEpic";
import { sampleCollectionEpics } from "../Pages/LabPages/SampleCollection/Redux/sampleCollectionEpic";
import { partyPaymentEpics } from "../Pages/InventoryPages/PartyPayment/Redux/partyPaymentEpic";
// clinic epics
import { clinicSetupEpics } from "../Pages/ClinicPages/ClinicSetup/Redux/clinicSetupEpic";
import { billingEpics } from "../Pages/ClinicPages/Billing/Redux/billingEpic";
import { patientListEpics } from "../Pages/ClinicPages/PatientDetails/Redux/patientDetailEpic";
import { appointmentEpics } from "../Pages/ClinicPages/Appointment/Redux/appointmentEpic";
import { medicalReferralEpics } from "../Pages/ClinicPages/Appointment/ConfirmModal/Redux/medicalReferralEpic";
import { referralEpics } from "../Pages/ClinicPages/Referral/Redux/referralEpic";
import { referralEditEpics } from "../Pages/ClinicPages/ReferralEdit/Redux/referralEditEpic";
import { siteSetupEpic } from "../Pages/ClinicPages/SiteSetup/Redux/siteSetupEpic";
import { referralTypeEpic } from "../Pages/ClinicPages/ReferralType/Redux/referralTypeEpic";
import { moldTypeEpics } from "../Pages/ClinicPages/MoldType/Redux/moldTypeEpic";
import { appointmentTypeEpic } from "../Pages/ClinicPages/AppointmentType/Redux/appointmentTypeEpic";
import { doctorEpics } from "../Pages/ClinicPages/Doctor/Redux/doctorEpic";
import { appointmentRoutineEpics } from "../Pages/ClinicPages/AppointmentRoutineSetup/Redux/appointmentRoutineEpic";
import { repairOrderEpics } from "../Pages/ClinicPages/RepairOrder/Redux/repairOrderEpic";
import { depositEpics } from "../Pages/ClinicPages/Deposit/Redux/depositEpic";
import { deviceRepairSpecialistEpic } from "../Pages/ClinicPages/DeviceRepairSpecialist/Redux/DeviceRepairSpecialistEpic";
//common viewDetails
import { itemListDetailEpics } from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailEpic";
import { purchaseReportEpics } from "../Pages/InventoryPages/Purchase/PurchaseReport/Redux/purchaseReportEpic";
import { saleReportEpics } from "../Pages/InventoryPages/Purchase/SaleReport/Redux/saleReportEpic";
import { verificationAndPrintEpics } from "../Pages/LabPages/VerificationAndPrint/Redux/verificationAndPrintEpic";
import { customerEditEpics } from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditEpic";
import { purchaseReturnEpics } from "../Pages/InventoryPages/PurchaseReturnPages/Redux/purchaseReturnEpic";
import { saleEpics } from "../Pages/InventoryPages/Sale/Redux/saleEpic";
import { commonAppEpics } from "../Pages/CommonAppRedux/commonAppEpic";
import { saleReturnEpics } from "../Pages/InventoryPages/SaleReturn/Redux/saleReturnEpic";
import { userActivityLogReportEpics } from "../Pages/ClinicPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportEpic";
import { materializedReportEpics } from "../Pages/ClinicPages/IRDReport/MaterializedReport/Redux/materializedReportEpic";
import { userActivityLogReportInventoryEpics } from "../Pages/InventoryPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportInventoryEpic";
import { materializedReportInventoryEpics } from "../Pages/InventoryPages/IRDReport/MaterializedReport/Redux/materializedReportInventoryEpic";
import { IRDSyncEpics } from "../Pages/ClinicPages/IRDReport/IRDSync/Redux/irdSyncEpic";
import { IRDSyncInventoryEpics } from "../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventoryEpic";
import { saleClinicReportEpics } from "../Pages/ClinicPages/Billing/ClinicSaleReport/Redux/clinicSaleReportEpic";
import { customerEpics } from "../Pages/InventoryPages/Customer/Redux/customerEpic";
import { serviceTypeEpics } from "../Pages/CoreSetupPages/ServiceType/Redux/serviceTypeEpic";
import { jaatiTypeEpics } from "../Pages/CoreSetupPages/JaatiType/Redux/jaatiTypeEpic";
import { TotalPatientStatReportEpics } from "../Pages/ClinicPages/ClinicReport/TotalPatientStatReport/Redux/totalPatientStatReportEpic";
import { DepartmentWiseSaleReportEpics } from "../Pages/ClinicPages/ClinicReport/DepartmentWiseSaleReport/Redux/departmentWiseSaleReportEpic";
import { PatientListReportEpics } from "../Pages/ClinicPages/ClinicReport/PatientListReport/Redux/patientListReportEpic";
import { CustomerWiseReportEpics } from "../Pages/InventoryPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportEpic";
import { SupplierWiseReportEpics } from "../Pages/InventoryPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportEpic";
import { CustomerWiseClinicReportEpics } from "../Pages/ClinicPages/ClinicReport/ClinicCustomerWiseReport/Redux/clinicCustomerWiseReportEpic";
import { testClinicReportEpics } from "../Pages/ClinicPages/ClinicReport/ClinicTestReport/Redux/clinicTestReportEpic";
import { creditClearanceEpics } from "../Pages/InventoryPages/CreditClearancePages/Redux/creditClearanceEpic";
import { passwordEpics } from "../Pages/Auth/Password/Redux/passwordEpic";
import { userAddressEpic } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Address/Redux/addressEpic";
import { userContactEpic } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Contact/Redux/contactEpic";
import { noteTemplatesEpic } from "../Pages/ClinicPages/NoteTemplates/Redux/noteTemplateEpic";
import { customerNoteEpic } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Notes/Redux/noteEpic";
import { contactRequestEpic } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ContactRequest/Redux/contactRequestEpic";
import { deviceRepairEpic } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/DeviceRepair/Redux/deviceRepairEpic";
import { clinicOrderEpics } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ClinicOrder/Redux/clinicOrderEpic";
import { moldOrderEpics } from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/MoldOrder/Redux/moldOrderEpic";
import { deviceOrderEpics } from "../Pages/ClinicPages/DeviceOrder/Redux/deviceOrderEpic";
import { serviceOrderEpics } from "../Pages/ClinicPages/ServiceOrder/Redux/serviceOrderEpic";
import { moldOrderAcceptEpics } from "../Pages/ClinicPages/MoldOrder/Redux/moldOrderEpic";
export const RootEpic = combineEpics(
  //common app
  commonAppEpics,
  userEpics,
  paymentModeEpics,
  authEpics,
  countryEpics,
  provinceEpics,
  districtEpics,
  palikaEpics,
  taxGroupEpics,
  manufacturerEpics,
  itemUnitEpics,
  itemCategoryEpics,
  packingTypeEpics,
  itemEpics,
  supplierEpics,
  userRoleEpics,
  organizationEpics,
  organizationRuleEpics,
  titleEpics,
  discountSchemaEpics,
  customerTypeEpics,
  storeEpics,
  purchaseEpics,
  purchaseReturnEpics,
  additionalChargeEpic,
  bankEpic,
  serviceEpics,
  // inventory
  saleEpics,
  saleReturnEpics,
  customerEpics,
  // lab
  unitEpic,
  reportCommentEpics,
  sensitiveMedicineEpics,
  testMethodEpics,
  departmentEpics,
  testCategoryEpics,
  footerNoteEpics,
  referralEpics,
  referralEditEpics,
  testSensitiveMedicineEpics,
  testFindingEpics,
  testEpics,
  packageTestEpics,
  referenceRangeEpics,
  sampleCollectionEpics,
  reportFooterEpics,
  resultEntryEpics,
  refundBillingEpics,
  sampleTypeEpics,
  reagentEpics,
  verificationAndPrintEpics,
  userActivityLogReportEpics,
  materializedReportEpics,
  partyPaymentEpics,
  // clinic
  appointmentEpics,
  billingEpics,
  clinicSetupEpics,
  siteSetupEpic,
  referralTypeEpic,
  appointmentTypeEpic,
  doctorEpics,
  appointmentRoutineEpics,
  medicalReferralEpics,
  moldTypeEpics,
  repairOrderEpics,
  patientListEpics,
  depositEpics,
  saleClinicReportEpics,
  deviceRepairSpecialistEpic,
  //viewDetails
  itemListDetailEpics,
  //purchase report
  purchaseReportEpics,
  saleReportEpics,
  //foreign worker registration
  //customer edit
  customerEditEpics,
  //referral login
  // authReferralEpics,
  //ird
  userActivityLogReportInventoryEpics,
  materializedReportInventoryEpics,
  IRDSyncEpics,
  IRDSyncInventoryEpics,
  serviceTypeEpics,
  jaatiTypeEpics,
  // WardEpics,
  //clinic all report
  TotalPatientStatReportEpics,
  DepartmentWiseSaleReportEpics,
  PatientListReportEpics,
  CustomerWiseClinicReportEpics,
  //inventory report
  CustomerWiseReportEpics,
  SupplierWiseReportEpics,
  //
  testClinicReportEpics,
  creditClearanceEpics,
  passwordEpics,
  // Root Epic for user Address and user contact inside patient details
  userAddressEpic,
  userContactEpic,

  // Root Epic for Note Templates
  noteTemplatesEpic,

  // Root Epic for customer Note inside patient details page
  customerNoteEpic,

  // Root Epic for contactRequest inside appointment module
  contactRequestEpic,

  // Root Epic for Repair module inside appointment module
  deviceRepairEpic,
  clinicOrderEpics,
  moldOrderEpics,
  deviceOrderEpics,
  serviceOrderEpics,
  moldOrderAcceptEpics
);
