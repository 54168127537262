import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  moldOrders: [],
  moldOrderDetails: [],
  loadingMoldOrder: false,
  loadingMoldOrderDepartment: false,
  loadingMoldOrderCategory: false,
  loadingMoldOrderCustomerType: false,
  loadingCreateMoldOrder: false,
  edit: false,
  moldOrderDetailEdit: false,
  moldOrder: null,
  moldOrderDetail: null,
  customerTypes: [],
  departments: [],
  moldOrderCategories: [],
  count: 0,
  previous: "",
  next: "",
  loadingMoldOrderDetailsAdded: false,
  // loadingHospitalReferralCustomer:false,
  loadingMoldOrderCustomer: false,
  moldOrderCustomers: [],
  loadingMoldOrderItem: false,
  loadingHospitalReferralItem: false,
  moldOrderItems: [],
  outerMoldTypes: [],
  loadingOuterMoldType: false,
  // state for received and dispatched module
  loadingUpdateReceivedStatus: false,
  loadingUpdateDispatchedStatus: false,
};

export const MoldOrderAccept = createSlice({
  name: "MoldOrder Accept Reducer",
  initialState,
  reducers: {
    loadingMoldOrder: (state) => {
      state.loadingMoldOrder = true;
    },
    getMoldOrderRequest: (state, payload) => {
      state.loadingMoldOrder = true;
    },
    getMoldOrderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingMoldOrder = false;
      state.moldOrders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getMoldOrderFail: (state) => {
      state.loadingMoldOrder = false;
    },
    getMoldOrderNextRequest: (state, payload) => {
      state.loadingMoldOrder = true;
    },
    getMoldOrderPreviousRequest: (state, payload) => {
      state.loadingMoldOrder = true;
    },
    getMoldOrderCustomerTypeRequest: (state, payload) => {
      state.loadingMoldOrderCustomerType = true;
    },
    getMoldOrderCustomerTypeSuccess: (state, { payload: { results } }) => {
      state.loadingMoldOrderCustomerType = false;
      state.customerTypes = results;
    },
    getMoldOrderCustomerTypeFail: (state) => {
      state.loadingMoldOrderCustomerType = false;
    },
    getMoldOrderDepartmentRequest: (state, payload) => {
      state.loadingMoldOrderDepartment = true;
    },
    getMoldOrderDepartmentSuccess: (state, { payload: { results } }) => {
      state.loadingMoldOrderDepartment = false;
      state.departments = results;
    },
    getMoldOrderDepartmentFail: (state) => {
      state.loadingMoldOrderDepartment = false;
    },
    getMoldOrderCategoryRequest: (state, payload) => {
      state.loadingMoldOrderCategory = true;
    },
    getMoldOrderCategorySuccess: (state, { payload: { results } }) => {
      state.loadingMoldOrderCategory = false;
      state.moldOrderCategories = results;
    },
    getMoldOrderCategoryFail: (state) => {
      state.loadingMoldOrderCategory = false;
    },
    createMoldOrderRequest: (state, payload) => {
      state.loadingCreateMoldOrder = true;
      state.closeModal = true;
    },
    createMoldOrderSuccess: (state) => {
      state.loadingCreateMoldOrder = false;
    },
    createMoldOrderFail: (state) => {
      state.loadingCreateMoldOrder = false;
    },
    getMoldOrderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    moldOrderEditSuccess: (state, { payload }) => {
      // const editableRow = state.moldOrders?.find(
      //   (moldOrder: any) => moldOrder?.id === payload?.id
      // );
      state.edit = true;
      state.moldOrder = payload;
      state.moldOrderDetails = payload?.moldOrderDetails;
    },
    moldOrderEditFail: (state) => {
      state.edit = false;
      state.moldOrder = null;
      state.moldOrderDetails = null;
    },
    moldOrderDetailEditSuccess: (state, { payload }) => {
      const updatedMoldOrderDetail = state.moldOrderDetails.find(
        (moldOrder: any) => moldOrder?.id === payload?.id
      );
      const updatedMoldOrderDetails = state.moldOrderDetails.filter(
        (moldOrder: any) => moldOrder.id !== payload.id
      );
      state.moldOrderDetail = updatedMoldOrderDetail;
      state.moldOrderDetails = updatedMoldOrderDetails;
      state.moldOrderDetailEdit = true;
      // state.loadingMoldOrder = false;
    },
    moldOrderDetailUpdateSuccess: (state, { payload }) => {
      state.moldOrderDetails = [...state.moldOrderDetails, payload];
      state.moldOrderDetailEdit = false;
      state.edit = true;
      state.loadingMoldOrderDetailsAdded = false;
    },
    moldOrderDetailAddedSuccess: (state, { payload }) => {
      state.moldOrderDetails = [...state.moldOrderDetails, payload];
      state.moldOrderDetailEdit = false;
      state.loadingMoldOrderDetailsAdded = false;
    },
    moldOrderDetailDeleteSuccess: (state, { payload }) => {
      const updatedMoldOrderDetails = state.moldOrderDetails.filter(
        (moldOrder: any) => moldOrder.unique_id !== payload.unique_id
      );
      state.moldOrderDetails = updatedMoldOrderDetails;
      state.moldOrderDetailEdit = false;
      state.loadingMoldOrderDetailsAdded = false;
    },
    updateMoldOrderRequest: (state, payload) => {
      state.loadingCreateMoldOrder = true;
    },
    updateMoldOrderSuccess: (state) => {
      state.loadingCreateMoldOrder = false;
    },
    updateMoldOrderFail: (state) => {
      state.loadingCreateMoldOrder = false;
    },
    clearMoldOrderData: (state) => {
      state.edit = false;
      state.moldOrder = null;
      state.moldOrderDetail = null;
      state.moldOrderDetails = [];
      state.loadingMoldOrder = false;
      state.moldOrderDetailEdit = false;
    },
    // customer slice
    loadingMoldOrderCustomerRequest: (state) => {
      state.loadingMoldOrderCustomer = true;
    },
    getMoldOrderCustomerRequest: (state, payload) => {
      state.loadingMoldOrderCustomer = true;
    },
    getMoldOrderCustomerSuccess: (state, { payload: { results } }) => {
      state.loadingMoldOrderCustomer = false;
      state.moldOrderCustomers = results;
    },
    getMoldOrderCustomerFail: (state) => {
      state.loadingMoldOrderCustomer = false;
    },
    //
    getMoldOrderItemSuccess: (state, { payload: { results } }) => {
      state.loadingMoldOrderItem = false;
      state.moldOrderItems = results;
    },
    getMoldOrderItemFail: (state) => {
      state.loadingMoldOrderItem = false;
    },
    loadingMoldOrderItemRequest: (state) => {
      state.loadingMoldOrderItem = true;
    },
    getMoldOrderItemRequest: (state, payload) => {
      state.loadingHospitalReferralItem = true;
    },
    //
    loadingOuterMoldType: (state) => {
      state.loadingOuterMoldType = true;
    },
    getOuterMoldTypeRequest: (state, payload) => {
      state.loadingOuterMoldType = true;
    },
    getOuterMoldTypeSuccess: (state, { payload: { results } }) => {
      state.loadingOuterMoldType = false;
      state.outerMoldTypes = results;
    },
    getOuterMoldTypeFail: (state) => {
      state.loadingOuterMoldType = false;
    },
    // update received status
    updateReceivedStatusRequest: (state, payload) => {
      state.loadingUpdateReceivedStatus = true;
    },
    updateReceivedStatusSuccess: (state) => {
      state.loadingUpdateReceivedStatus = false;
    },
    updateReceivedStatusFail: (state) => {
      state.loadingUpdateReceivedStatus = false;
    },
    // update dispatched status
    updateDispatchedStatusRequest: (state, payload) => {
      state.loadingUpdateDispatchedStatus = true;
    },
    updateDispatchedStatusSuccess: (state) => {
      state.loadingUpdateDispatchedStatus = false;
    },
    updateDispatchedStatusFail: (state) => {
      state.loadingUpdateDispatchedStatus = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingMoldOrder,
  getMoldOrderRequest,
  getMoldOrderSuccess,
  getMoldOrderFail,
  getMoldOrderNextRequest,
  getMoldOrderPreviousRequest,
  getMoldOrderCustomerTypeRequest,
  getMoldOrderCustomerTypeSuccess,
  getMoldOrderCustomerTypeFail,
  getMoldOrderDepartmentRequest,
  getMoldOrderDepartmentSuccess,
  getMoldOrderDepartmentFail,
  getMoldOrderCategoryRequest,
  getMoldOrderCategorySuccess,
  getMoldOrderCategoryFail,
  createMoldOrderRequest,
  createMoldOrderSuccess,
  createMoldOrderFail,
  moldOrderEditSuccess,
  moldOrderEditFail,
  moldOrderDetailEditSuccess,
  moldOrderDetailUpdateSuccess,
  moldOrderDetailAddedSuccess,
  moldOrderDetailDeleteSuccess,
  updateMoldOrderRequest,
  updateMoldOrderSuccess,
  updateMoldOrderFail,
  clearMoldOrderData,
  getMoldOrderByIdRequest,
  //
  loadingMoldOrderCustomerRequest,
  getMoldOrderCustomerRequest,
  getMoldOrderCustomerSuccess,
  getMoldOrderCustomerFail,
  //
  getMoldOrderItemSuccess,
  getMoldOrderItemFail,
  loadingMoldOrderItemRequest,
  getMoldOrderItemRequest,
  //
  loadingOuterMoldType,
  getOuterMoldTypeRequest,
  getOuterMoldTypeSuccess,
  getOuterMoldTypeFail,
  //
  updateReceivedStatusRequest,
  updateReceivedStatusSuccess,
  updateReceivedStatusFail,
  //
  updateDispatchedStatusRequest,
  updateDispatchedStatusSuccess,
  updateDispatchedStatusFail,
} = MoldOrderAccept.actions;
export default MoldOrderAccept.reducer;
