import { combineReducers } from "@reduxjs/toolkit";
import CommonAppSlice from "../Pages/CommonAppRedux/CommonAppSlice";
import PaymentMode from "../Pages/CoreSetupPages/PaymentMode/Redux/PaymentModeSlice";
import User from "../Pages/CoreSetupPages/User/Redux/userSlice";
import Country from "../Pages/CoreSetupPages/Country/Redux/countrySlice";
import Province from "../Pages/CoreSetupPages/Province/Redux/provinceSlice";
import District from "../Pages/CoreSetupPages/District/Redux/districtSlice";
import Palika from "../Pages/CoreSetupPages/Palika/Redux/palikaSlice";
import TaxGroup from "../Pages/CoreSetupPages/TaxGroup/Redux/taxGroupSlice";
import Auth from "../Pages/Auth/Redux/authSlice";
import Manufacturer from "../Pages/InventoryPages/Manufacturer/Redux/manufacturerSlice";
import ItemUnit from "../Pages/InventoryPages/ItemUnit/Redux/itemUnitSlice";
import ItemCategory from "../Pages/InventoryPages/ItemCategory/Redux/itemCategorySlice";
import PackingType from "../Pages/InventoryPages/PackingType/Redux/packingTypeSlice";
import Item from "../Pages/InventoryPages/Item/Redux/itemSlice";
import Customer from "../Pages/InventoryPages/Customer/Redux/customerSlice";
// core set up
import Supplier from "../Pages/InventoryPages/Supplier/Redux/supplierSlice";
import UserRole from "../Pages/CoreSetupPages/UserRole/Redux/userRoleSlice";
import Organization from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationSlice";
import Title from "../Pages/CoreSetupPages/Title/Redux/titleSlice";
import DiscountSchema from "../Pages/CoreSetupPages/DiscountScheme/Redux/DiscountSchemeSlice";
import CustomerType from "../Pages/CoreSetupPages/CustomerType/Redux/customerTypeSlice";
import Store from "../Pages/CoreSetupPages/Store/Redux/storeSlice";
import Purchase from "../Pages/InventoryPages/Purchase/Redux/purchaseSlice";
import AdditionalCharge from "../Pages/CoreSetupPages/AdditionalChargeType/Redux/AdditionalChargeSlice";
import Bank from "../Pages/CoreSetupPages/Bank/Redux/BankSlice";
import OrganizationRule from "../Pages/CoreSetupPages/OrganizationRule/Redux/organizationRuleSlice";
import Service from "../Pages/InventoryPages/Service/Redux/serviceSlice";
// lab reducers
import Department from "../Pages/LabPages/Department/Redux/departmentSlice";
import TestCategory from "../Pages/LabPages/TestCategory/Redux/testCategorySlice";
import FooterNote from "../Pages/LabPages/FooterNote/Redux/footerNoteSlice";
import ReportComment from "../Pages/LabPages/ReportComment/Redux/reportCommentSlice";
import SensitiveMedicine from "../Pages/LabPages/SensitiveMedicine/Redux/sensitiveMedicineSlice";
import TestMethod from "../Pages/LabPages/TestMethod/Redux/testMethodSlice";

import Unit from "../Pages/LabPages/Unit/Redux/UnitSlice";
import TestSensitiveMedicine from "../Pages/LabPages/TestSensitiveMedicine/Redux/testSensitiveMedicineSlice";
import TestFinding from "../Pages/LabPages/TestFinding/Redux/testFindingSlice";
import Test from "../Pages/LabPages/Test/Redux/testSlice";
import PackageTest from "../Pages/LabPages/PackageTest/Redux/packageTestSlice";
import ReferenceRange from "../Pages/LabPages/ReferenceRange/Redux/referenceRangeSlice";
import ReportFooter from "../Pages/LabPages/ReportFooter/Redux/reportFooterSlice";

import SampleType from "../Pages/LabPages/SampleType/Redux/sampleTypeSlice";
import Reagent from "../Pages/LabPages/Reagent/Redux/reagentSlice";
//extra
import PaymentDetails from "../Pages/CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
import SampleCollection from "../Pages/LabPages/SampleCollection/Redux/SampleCollectionSlice";
import ResultEntry from "../Pages/LabPages/ResultEntry/Redux/ResultEntrySlice";
// clinic reducers
import Appointment from "../Pages/ClinicPages/Appointment/Redux/appointmentSlice";
import SiteSetup from "../Pages/ClinicPages/SiteSetup/Redux/siteSetupSlice";
import ReferralType from "../Pages/ClinicPages/ReferralType/Redux/referralTypeSlice";
import MoldType from "../Pages/ClinicPages/MoldType/Redux/moldTypeSlice";
import AppointmentType from "../Pages/ClinicPages/AppointmentType/Redux/appointmentTypeSlice";
import Doctor from "../Pages/ClinicPages/Doctor/Redux/doctorSlice";
import AppointmentRoutine from "../Pages/ClinicPages/AppointmentRoutineSetup/Redux/appointmentRoutineSlice";
import PatientDetail from "../Pages/ClinicPages/PatientDetails/Redux/patientDetailSlice";
import MedicalReferral from "../Pages/ClinicPages/Appointment/ConfirmModal/Redux/medicalReferralSlice";
import ClinicSetup from "../Pages/ClinicPages/ClinicSetup/Redux/clinicSetupSlice";
import RepairOrder from "../Pages/ClinicPages/RepairOrder/Redux/repairOrderSlice";
import Billing from "../Pages/ClinicPages/Billing/Redux/billingSlice";
import RefundBilling from "../Pages/ClinicPages/RefundBilling/Redux/refundBillingSlice";
import Referral from "../Pages/ClinicPages/Referral/Redux/referralSlice";
import ReferralEdit from "../Pages/ClinicPages/ReferralEdit/Redux/referralEditSlice";
import SaleClinicReport from "../Pages/ClinicPages/Billing/ClinicSaleReport/Redux/clinicSaleReportSlice";
import Deposit from "../Pages/ClinicPages/Deposit/Redux/depositSlice";
import DeviceRepairSpecialist from "../Pages/ClinicPages/DeviceRepairSpecialist/Redux/DeviceRepairSpecialistSlice";
//viewDetails
import ItemListDetail from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailSlice";
import PurchaseReport from "../Pages/InventoryPages/Purchase/PurchaseReport/Redux/purchaseReportSlice";
import SaleReport from "../Pages/InventoryPages/Purchase/SaleReport/Redux/saleReportSlice";
import VerificationAndPrint from "../Pages/LabPages/VerificationAndPrint/Redux/verificationAndPrintSlice";
import CustomerEdit from "../Pages/CoreSetupPages/CustomerEdit/Redux/customerEditSlice";
import PurchaseReturn from "../Pages/InventoryPages/PurchaseReturnPages/Redux/purchaseReturnSlice";
import Sale from "../Pages/InventoryPages/Sale/Redux/saleSlice";
import SaleReturn from "../Pages/InventoryPages/SaleReturn/Redux/saleReturnSlice";
import UserActivityLogReport from "../Pages/ClinicPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportSlice";
import MaterializedReport from "../Pages/ClinicPages/IRDReport/MaterializedReport/Redux/materializedReportSlice";
import UserActivityLogReportInventory from "../Pages/InventoryPages/IRDReport/UserActivityLogReport/Redux/userActivityLogReportInventorySlice";
import MaterializedReportInventory from "../Pages/InventoryPages/IRDReport/MaterializedReport/Redux/materializedReportInventorySlice";
import IRDSync from "../Pages/ClinicPages/IRDReport/IRDSync/Redux/irdSyncSlice";
import IRDSyncInventory from "../Pages/InventoryPages/IRDReport/IRDSync/Redux/irdSyncInventorySlice";
import ServiceType from "../Pages/CoreSetupPages/ServiceType/Redux/serviceTypeSlice";
import jaatiType from "../Pages/CoreSetupPages/JaatiType/Redux/jaatiTypeSlice";
import TotalPatientStatReport from "../Pages/ClinicPages/ClinicReport/TotalPatientStatReport/Redux/totalPatientStatReportSlice";
import DepartmentWiseSaleReport from "../Pages/ClinicPages/ClinicReport/DepartmentWiseSaleReport/Redux/departmentWiseSaleReportSlice";
import PatientListReport from "../Pages/ClinicPages/ClinicReport/PatientListReport/Redux/patientListReportSlice";
import CustomerWiseReport from "../Pages/InventoryPages/InventoryReport/CustomerWiseReport/Redux/customerWiseReportSlice";
import SupplierWiseReport from "../Pages/InventoryPages/InventoryReport/SupplierWiseSaleReport/Redux/supplierWiseReportSlice";
import CustomerWiseClinicReport from "../Pages/ClinicPages/ClinicReport/ClinicCustomerWiseReport/Redux/clinicCustomerWiseReportSlice";
import TestClinicReport from "../Pages/ClinicPages/ClinicReport/ClinicTestReport/Redux/clinicTestReportSlice";
//
import PartyPayment from "../Pages/InventoryPages/PartyPayment/Redux/partyPaymentSlice";
import CreditClearance from "../Pages/InventoryPages/CreditClearancePages/Redux/creditClearanceSlice";
import Password from "../Pages/Auth/Password/Redux/passwordSlice";
import addressSlice from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Address/Redux/addressSlice";
import contactSlice from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Contact/Redux/contactSlice";
import noteTemplateSlice from "../Pages/ClinicPages/NoteTemplates/Redux/noteTemplateSlice";
import noteSlice from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/Notes/Redux/noteSlice";
import contactRequestSlice from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ContactRequest/Redux/contactRequestSlice";
import deviceRepairSlice from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/DeviceRepair/Redux/deviceRepairSlice";
import clinicOrder from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/ClinicOrder/Redux/clinicOrderSlice";
import MoldOrder from "../Pages/ClinicPages/PatientDetails/PatientDetailsPages/MoldOrder/Redux/moldOrderSlice";
import DeviceOrder from "../Pages/ClinicPages/DeviceOrder/Redux/deviceOrderSlice";
import ServiceOrder from "../Pages/ClinicPages/ServiceOrder/Redux/serviceOrderSlice";
import MoldOrderAccept from "../Pages/ClinicPages/MoldOrder/Redux/moldOrderSlice";

export const rootReducer = combineReducers({
  // master
  commonApp: CommonAppSlice,
  user: User,
  auth: Auth,
  paymentMode: PaymentMode,
  country: Country,
  province: Province,
  district: District,
  palika: Palika,
  taxGroup: TaxGroup,
  userRole: UserRole,
  organization: Organization,
  title: Title,
  discountSchema: DiscountSchema,
  customerType: CustomerType,
  store: Store,
  additionalCharge: AdditionalCharge,
  bank: Bank,
  paymentDetail: PaymentDetails,
  organizationRule: OrganizationRule,
  // inventory
  item: Item,
  manufacturer: Manufacturer,
  itemUnit: ItemUnit,
  itemCategory: ItemCategory,
  packingType: PackingType,
  supplier: Supplier,
  purchase: Purchase,
  purchaseReturn: PurchaseReturn,
  service: Service,
  //purchase Report
  purchaseReport: PurchaseReport,
  saleReport: SaleReport,
  saleReturn: SaleReturn,
  // lab
  billing: Billing,
  refundBilling: RefundBilling,
  unit: Unit,
  department: Department,
  testCategory: TestCategory,
  footerNote: FooterNote,
  reportComment: ReportComment,
  sensitiveMedicine: SensitiveMedicine,
  testMethod: TestMethod,
  referral: Referral,
  referralEdit: ReferralEdit,
  testFinding: TestFinding,
  test: Test,
  packageTest: PackageTest,
  referenceRange: ReferenceRange,
  sampleCollection: SampleCollection,
  reportFooter: ReportFooter,
  resultEntry: ResultEntry,
  sampleType: SampleType,
  reagent: Reagent,
  verificationAndPrint: VerificationAndPrint,
  partyPayment: PartyPayment,
  //ird
  userActivityLogReport: UserActivityLogReport,
  materializedReport: MaterializedReport,
  irdSync: IRDSync,

  // inv common app
  testSensitiveMedicine: TestSensitiveMedicine,
  inventorySale: Sale,
  customer: Customer,
  // clinic
  appointment: Appointment,
  clinicSetup: ClinicSetup,
  siteSetup: SiteSetup,
  patientDetail: PatientDetail,
  referralType: ReferralType,
  appointmentType: AppointmentType,
  doctor: Doctor,
  appointmentRoutine: AppointmentRoutine,
  medicalReferral: MedicalReferral,
  saleClinicReport: SaleClinicReport,
  moldType: MoldType,
  repairOrder: RepairOrder,
  deposit: Deposit,
  deviceRepairSpecialist: DeviceRepairSpecialist,
  //clinic admin setup
  //viewDetails
  itemListDetail: ItemListDetail,
  //foreign worker
  //customer edit
  customerEdit: CustomerEdit,
  //referral login,
  //inventory
  userActivityLogReportInventory: UserActivityLogReportInventory,
  materializedReportInventory: MaterializedReportInventory,
  irdSyncInventory: IRDSyncInventory,
  //core setup
  serviceType: ServiceType,
  jaatiType: jaatiType,
  // Ward:Ward,
  //clinic all report
  totalPatientStatReport: TotalPatientStatReport,
  departmentWiseSaleReport: DepartmentWiseSaleReport,
  patientListReport: PatientListReport,
  customerWiseClinicReport: CustomerWiseClinicReport,
  //inventory
  customerWiseReport: CustomerWiseReport,
  supplierWiseReport: SupplierWiseReport,
  //clinic
  testClinicReport: TestClinicReport,

  creditClearance: CreditClearance,
  //change password
  password: Password,

  // user address and user contact inside patient details
  userAddress: addressSlice,
  userContact: contactSlice,

  // noteTemplates Reducer for Note Templates inside clinic admin setup
  noteTemplates: noteTemplateSlice,

  // customer Note Reducer for customer Note inside patient details
  customerNote: noteSlice,

  // Contact Request module inside Appointment module
  contactRequest: contactRequestSlice,

  // Repair Module inside Appointment module
  deviceRepair: deviceRepairSlice,
  clinicOrder: clinicOrder,

  moldOrder: MoldOrder,
  deviceOrder: DeviceOrder,
  serviceOrder: ServiceOrder,
  moldOrderAccept: MoldOrderAccept,
});
