// inventory permission
export const itemPermission = [
  "view_pos_item",
  "add_pos_item",
  "edit_pos_item",
];
export const itemCategoryPermission = [
  "view_pos_item_category",
  "add_pos_item_category",
  "edit_pos_item_category",
];
export const invPackingTypePermission = [
  "view_pos_packing_type",
  "add_pos_packing_type",
  "edit_pos_packing_type",
];
export const invItemUnitPermission = [
  "view_pos_item_unit",
  "add_pos_item_unit",
  "edit_pos_item_unit",
];
export const invManufacturerPermission = [
  "view_pos_manufacturer",
  "add_pos_manufacturer",
  "edit_pos_manufacturer",
];
export const invServicePermission = [
  "view_pos_service_setup",
  "add_pos_service_setup",
  "edit_pos_service_setup",
];
export const invCustomerPermission = [
  "view_pos_customer_registration",
  "add_pos_customer_registration",
  "edit_pos_customer_registration",
];
export const invSupplierPermission = [
  "view_pos_supplier_setup",
  "add_pos_supplier_setup",
  "edit_pos_supplier_setup",
];
export const invPurchasePermission = [
  "view_pos_purchase",
  "add_pos_purchase",
  "edit_pos_purchase",
];
export const invPurchaseReturnPermission = [
  "view_pos_purchase_return",
  "add_pos_purchase_return",
  "edit_pos_purchase_return",
];
export const invSalePermission = [
  "view_pos_sale",
  "add_pos_sale",
  "edit_pos_sale",
  "delete_pos_sale",
  "print_pos_sale_invoice",
];
export const invSaleReturnPermission = [
  "view_post_sale_return",
  "add_post_sale_return",
  "edit_post_sale_return",
];
export const invItemRequestPermission = [
  "view_item_request",
  "add_item_request",
];
export const invItemTransferPermission = ["view_item_transfer"];
export const invStockAnalysisPermission = ["view_pos_stock_analysis"];
export const invPurchaseReportPermission = [
  "view_pos_purchase_report",
  "print_pos_purchase_report",
  "export_pdf_pos_purchase_report",
  "export_excel_pos_purchase_report",
];
export const invSaleReportPermission = [
  "view_pos_sale_report",
  "print_pos_sale_report",
  "export_pdf_pos_sale_report",
  "export_excel_pos_sale_report",
];
export const invSupplierWiseReportPermission = [
  "view_pos_supplier_wise_revenue_report",
  "print_pos_supplier_wise_revenue_report",
  "export_pdf_pos_supplier_wise_revenue_report",
  "export_excel_pos_supplier_wise_revenue_report",
];
export const invCustomerWiseReportPermission = [
  "view_pos_customer_wise_revenue_report",
];
export const invPartyPaymentPermission = [
  "view_pos_party_payment",
  "add_pos_party_payment",
  "edit_pos_party_payment",
];
export const invCreditClearancePermission = [
  "view_pos_credit_clearance",
  "add_pos_credit_clearance",
  "edit_pos_credit_clearance",
];
export const invMaterializedReportPermission = [
  "view_pos_materialized_report",
  "print_pos_materialized_report",
  "export_pdf_pos_materialized_report",
  "export_excel_pos_materialized_report",
];
export const invUserActivityLogReportPermission = [
  "view_pos_user_activity_log_report",
  "print_pos_user_activity_log_report",
  "export_pdf_pos_user_activity_log_report",
  "export_excel_pos_user_activity_log_report",
];
export const invIrdSyncPermission = ["view_pos_ird_sync", "add_pos_ird_sync"];
