import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import userRole from "../../../../Assets/CoreSetup/userRole.webp";
// custom icon
import appointmentIcon from "../../../../Assets/icons/ClinicIcons/appointment.svg";
import billingIcon from "../../../../Assets/icons/ClinicIcons/billing.svg";
import clinicIcon from "../../../../Assets/icons/ClinicIcons/clinic.svg";
import correctionIcon from "../../../../Assets/icons/ClinicIcons/correction.svg";
import insuranceIcon from "../../../../Assets/icons/ClinicIcons/insurance.svg";
import patientIcon from "../../../../Assets/icons/ClinicIcons/patient.svg";
import financialIcon from "../../../../Assets/icons/ClinicIcons/financial.svg";
//customer Mid icon
import clinicSaleReport from "../../../../Assets/icons/ClinicMidIcons/clinic_sale_report.svg";
import departmentWiseReport from "../../../../Assets/icons/ClinicMidIcons/department_wise_report.svg";
import directBiling from "../../../../Assets/icons/ClinicMidIcons/direct_billing.svg";
import doctor from "../../../../Assets/icons/ClinicMidIcons/doctor.svg";
import followUp from "../../../../Assets/icons/ClinicMidIcons/followup.svg";
import hospitalRefferal from "../../../../Assets/icons/ClinicMidIcons/hospital_refferal.svg";
import insuranceClaim from "../../../../Assets/icons/ClinicMidIcons/insurance_claim.svg";
import patientListReport from "../../../../Assets/icons/ClinicMidIcons/patient_list_report.svg";
import refundBilling from "../../../../Assets/icons/ClinicMidIcons/refund_billing.svg";
import saleTestReport from "../../../../Assets/icons/ClinicMidIcons/sale_test_report.svg";
import totalPatientStatReport from "../../../../Assets/icons/ClinicMidIcons/total_patient_stat_report.svg";

//common mid icons

import customerWiseReport from "../../../../Assets/icons/commonMidIcons/customer_wise_report.svg";
import irdSync from "../../../../Assets/icons/commonMidIcons/irdsync.svg";
import materializedReport from "../../../../Assets/icons/commonMidIcons/materialized-report.svg";
import patient from "../../../../Assets/icons/commonMidIcons/patient.svg";
import userActivityLogReport from "../../../../Assets/icons/commonMidIcons/user_activity_log_report.svg";
//

import appointmentRoutine from "../../../../Assets/icons/ClinicMidIcons/appointment_routine.svg";
import appointmentType from "../../../../Assets/icons/ClinicMidIcons/appointment_type.svg";
import appointment from "../../../../Assets/icons/ClinicMidIcons/appointment.svg";
import clinicSetup from "../../../../Assets/icons/ClinicMidIcons/clinic_setup.svg";

import noteTemplate from "../../../../Assets/icons/ClinicMidIcons/note_template.svg";
import patientDetail from "../../../../Assets/icons/ClinicMidIcons/patient_detail.svg";
import referrerEdit from "../../../../Assets/icons/ClinicMidIcons/refferer_edit.svg";
import reffererType from "../../../../Assets/icons/ClinicMidIcons/refferer_type.svg";
import siteSetup from "../../../../Assets/icons/ClinicMidIcons/site_setup.svg";
//
import customerIcon from "../../../../Assets/icons/InventoryIcons/customer.svg";

//
import {
  activityReportPermission,
  appointmentPermission,
  appointmentRoutinePermission,
  appointmentTypePermission,
  billingPermission,
  clinicPatientWiseReportPermission,
  clinicDepartmentWiseSaleReportPermission,
  clinicPatientListReportPermission,
  clinicSaleReportPermission,
  clinicSaleTestReportPermission,
  clinicSetupPermission,
  clinicTotalPatientReportPermission,
  doctorPermission,
  followUpPermission,
  foreignWorkerPermission,
  hospitalReferralPermission,
  insClaimPermission,
  irdSyncPermission,
  jobPostPermission,
  labMaterializedPermission,
  opdRegistrationPermission,
  patientDetailsPermission,
  quickRegistrationPermission,
  recruitmentPermission,
  referralEditPermission,
  referralTypePermission,
  referrerPermission,
  refundBillingPermission,
  relationPermission,
  sitePermission,
  moldOrderAcceptPermission,
  deviceOrderAcceptPermission,
  serviceOrderAcceptPermission,
  moldTypePermission,
  repairOrderAcceptPermission,
  depositPermission,
  deviceRepairSpecialistPermission,
} from "./ClinicPermissionConstants";
import { invServicePermission } from "../Inventory/InvPermissionConstants";
export const clinicSidebarData = [
  {
    name: "Billing",
    customIcon: billingIcon,
    items: [
      {
        name: "Direct Billing",
        image: directBiling,
        link: "/clinic/direct-billing",
        permission: billingPermission,
      },
      {
        name: "Refund Billing",
        link: "/clinic/refund-billing",
        image: refundBilling,
        permission: refundBillingPermission,
      },
    ],
  },
  {
    name: "Financial Report",
    customIcon: financialIcon,
    items: [
      {
        name: "Clinic Sale Report",
        link: "/clinic/sale-clinic-report",
        image: clinicSaleReport,
        permission: clinicSaleReportPermission,
      },
      {
        name: "Department Wise Sale Report",
        link: "/clinic/department-wise-sale-report",
        image: departmentWiseReport,
        permission: clinicDepartmentWiseSaleReportPermission,
      },
      // {
      //   name: "Total Patient Stat Report",
      //   link: "/clinic/total-patient-stat-report",
      //   image: totalPatientStatReport,
      //   permission: clinicTotalPatientReportPermission,
      // },
      {
        name: "Patient List Report",
        link: "/clinic/patient-list-report",
        image: patientListReport,
        permission: clinicPatientListReportPermission,
      },
      {
        name: "Customer Wise Report",
        link: "/clinic/customer-wise-report",
        image: customerWiseReport,
        permission: clinicPatientWiseReportPermission,
      },
      {
        name: "Sale Test Report",
        link: "/clinic/sale-test-report",
        image: saleTestReport,
        permission: clinicSaleTestReportPermission,
      },
    ],
  },

  // {
  //   name: "IRD Reports",
  //   customIcon: financialIcon,
  //   items: [
  //     {
  //       name: "User Activity Log Report",
  //       link: "/clinic/lab-ird-user-activity-report",
  //       image: userRole,
  //       permission: activityReportPermission,
  //     },
  //     {
  //       name: "Materialized Report",
  //       link: "/clinic/lab-ird-materialized-report",
  //       image: userRole,
  //       permission: labMaterializedPermission,
  //     },
  //     {
  //       name: "IRD Sync",
  //       link: "/clinic/ird-sync",
  //       image: userRole,
  //       permission: irdSyncPermission,
  //     },
  //   ],
  // },
  {
    name: "Clinic Admin Setup",
    customIcon: clinicIcon,
    items: [
      {
        name: "Doctor",
        link: "/clinic/doctor",
        image: doctor,
        permission: doctorPermission,
      },
      {
        name: "Referrer",
        link: "/clinic/referrer",
        image: referrerEdit,
        permission: referrerPermission,
      },
      {
        name: "Site Setup",
        link: "/clinic/site-setup",
        image: siteSetup,
        permission: sitePermission,
      },
      {
        name: "Referral Type",
        link: "/clinic/referral-type",
        image: reffererType,
        permission: referralTypePermission,
      },
      {
        name: "Appointment Type",
        link: "/clinic/appointment-type",
        image: appointmentType,
        permission: appointmentTypePermission,
      },
      {
        name: "Note Templates",
        link: "/clinic/note-templates",
        image: noteTemplate,
        permission: recruitmentPermission,
      },
      {
        name: "Mold Type",
        link: "/clinic/mold-type",
        image: noteTemplate,
        permission: moldTypePermission,
      },
    ],
  },
  {
    name: "Service",
    // for custom icons
    customIcon: customerIcon,
    items: [
      {
        name: `Service`,
        image: refundBilling,
        link: "/clinic/service",
        permission: invServicePermission,
      },
    ],
  },
  {
    name: "Appointment",
    // for custom icons
    customIcon: appointmentIcon,
    items: [
      {
        name: "Appointment",
        link: "/clinic/appointment",
        image: appointment,
        permission: appointmentPermission,
      },
      {
        name: "Patient Details",
        link: "/clinic/patient-details",
        pattern: true,
        image: patientDetail,
        permission: patientDetailsPermission,
      },
      {
        name: "Clinic Setup",
        link: "/clinic/clinic-setup",
        image: clinicSetup,
        permission: clinicSetupPermission,
      },
      {
        name: "Appointment Routine",
        link: "/clinic/appointment-routine",
        image: appointmentRoutine,
        permission: appointmentRoutinePermission,
      },
    ],
  },
  {
    name: "Correction",
    customIcon: correctionIcon,
    items: [
      {
        name: "Referrer Edit",
        link: "/clinic/referrer-edit",
        image: referrerEdit,
        permission: referralEditPermission,
      },
    ],
  },
  {
    name: "Order Management",
    customIcon: correctionIcon,
    items: [
      {
        name: "Mold Order",
        link: "/clinic/mold-order",
        image: referrerEdit,
        permission: moldOrderAcceptPermission,
      },
      {
        name: "Device Order",
        link: "/clinic/device-order",
        image: referrerEdit,
        permission: deviceOrderAcceptPermission,
      },
      {
        name: "Repair Order",
        link: "/clinic/repair-order",
        image: referrerEdit,
        permission: repairOrderAcceptPermission,
      },
      {
        name: "Deposit",
        link: "/clinic/deposit",
        image: referrerEdit,
        permission: depositPermission,
      },
      {
        name: "Device Repair Specialist",
        link: "/clinic/device-repair-specialist",
        image: referrerEdit,
        permission: deviceRepairSpecialistPermission,
      },
    ],
  },
];
