import {
  loginSuccess,
  loginFail,
  getBranchesSuccess,
  getBranchesFail,
  getBranchRequest,
  loadingLogin,
  resetPasswordReq,
  resetPasswordSuccess,
  resetPasswordFail,
  confirmPasswordReq,
  confirmPasswordSuccess,
  confirmPasswordFail,
  verifyEmailReq,
  verifyEmailFail,
  verifyEmailSuccess,
  verifyEmailLinkReq,
} from "./authSlice";
import messages from "../../../AppUtils/Utils/validationConstants";
import { mergeMap } from "rxjs";
import {
  confirmPassword,
  emailVerification,
  emailVerificationSuccess,
  getAllBranches,
  getLogin,
  resetPassword,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../CommonAppRedux/CommonAppSlice";
import { loginRequest } from "./authSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../AppUtils/Utils/globalTypes";

//get user epic
const login = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(loginRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingLogin());
      try {
        const response = await getLogin(action?.payload);
        dispatch(alertSuccessAction(messages.loginSuccess));
        return { payload: response.data };
      } catch (e) {
        dispatch(alertErrorAction(messages.loginFail));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? loginSuccess(action?.payload) : loginFail()
    )
  );
//get user epic
const getBranches = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBranchRequest.match),
    mergeMap(async () => {
      try {
        const response = await getAllBranches();
        return { payload: response.data };
      } catch (e) {
        dispatch(alertErrorAction(messages.branchFail));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBranchesSuccess(action?.payload) : getBranchesFail()
    )
  );

//reset password
const resetPasswordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(resetPasswordReq.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await resetPassword(body);
        return { payload: response.data };
      } catch (e) {
        //@ts-ignore
        dispatch(alertErrorAction(e?.response?.data?.detail));
        dispatch(resetPasswordFail());
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? resetPasswordSuccess(action?.payload)
        : resetPasswordFail()
    )
  );
//confirm password
const confirmPasswordEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(confirmPasswordReq.match),
    mergeMap(async ({ payload }) => {
      const { branch, history, ...restValues } = payload;
      try {
        const body = JSON.stringify(restValues);
        const response = await confirmPassword({ branch, body });
        history.push("/");
        return { payload: response.data };
      } catch (e) {
        //@ts-ignore
        dispatch(alertErrorAction(e?.response?.data?.detail));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? confirmPasswordSuccess(action?.payload)
        : confirmPasswordFail()
    )
  );

  //verify email 
const verifyEmailEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(verifyEmailReq.match),
    mergeMap(async ({ payload }) => {
      try {
        const body = JSON.stringify(payload);
        const response = await emailVerification(body);
        return { payload: response.data };
      } catch (e) {
        //@ts-ignore
        dispatch(alertErrorAction(e?.response?.data?.detail));
        dispatch(verifyEmailFail());
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? verifyEmailSuccess(action?.payload)
        : verifyEmailFail()
    )
  );
  //
  const verifyEmailLinkEpic = (
    action$: Observable<Action>,
    _: stateAction,
    { dispatch }: dispatchAction
  ) =>
    action$.pipe(
      filter(verifyEmailLinkReq.match),
      mergeMap(async ({ payload }) => {
        try {
          const body = JSON.stringify(payload);
          const response = await emailVerificationSuccess(body);
          return { payload: response.data };
        } catch (e) {
          //@ts-ignore
          dispatch(alertErrorAction(e?.response?.data?.detail));
          dispatch(verifyEmailFail());
          return { error: e };
        }
      }),
      map((action) =>
        action?.payload
          ? verifyEmailSuccess(action?.payload)
          : verifyEmailFail()
      )
    );

  
export const authEpics = combineEpics(
  login,
  getBranches,
  resetPasswordEpic,
  confirmPasswordEpic,
  verifyEmailEpic,
  verifyEmailLinkEpic,
  
);
